.calc {
  height: 400px;
  width: 300px;
}

.timesPhi-textarea {
  height: 200px;
  width: 399px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 24px;
  text-align:center;
  line-height: 200px;
}

.overPhi-textarea{
    height: 200px;
    width: 247px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 24px;
    text-align:center;
    line-height: 200px;

}

.calc-div {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 6%;
}

@media (max-width: 700px) {
  .calc-div {
    width: 100%;
  }
  .timesPhi-textarea {
    width: 100% !important;
    height: 399px !important;
  }

  .overPhi-textarea {
    width: 100%;
    height: 247px;
  }
}
